export class ApiConstants {
  // Key to handle Status
  static Status = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
  };
}

export enum PossibleMessageType {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    DOCUMENT = 'DOCUMENT',
    VOICE = 'VOICE',
}

/**
 * Keys for Message User Types
 */
export enum MessageUserType {
    BOT = 'BOT',
    USER = 'USER',
    ASSISTANT = 'ASSISTANT',
}

/**
 * Keys for Category Types
 */
export enum CategoryType {
    CATEGORY = 'CATEGORY',
    VECLOGIC_TYPE = 'VECLOGIC_TYPE',
    SHOP_SUBCATEGORY = 'SHOP_SUBCATEGORY',
    VECLOGIC_EMBEDDING = 'VECLOGIC_EMBEDDING',
    WEBSITE_CATEGORY = 'WEBSITE_CATEGORY',
}

/**
 * Keys for CMS Document Types
 */
export enum CmsDocumentType {
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
    ABOUT_US = 'ABOUT_US',
    CANCELLATION_POLICY = 'CANCELLATION_POLICY',
    EULA = 'EULA',
    MODERATION_POLICY = 'MODERATION_POLICY',
    CONTACT_US = 'CONTACT_US',
    OTHER = 'OTHER',
}

/**
 * Keys for Report Request Types
 */
export enum ReportRequestType {
    IN_REVIEW = 'IN_REVIEW',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}
