/* eslint-disable max-len */
import { NavigationExtras, Router } from '@angular/router';
import {
  NavigationScreenRoute,
  SideBarTabs,
} from 'src/app/constants/navigation-screen';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RedirectHandlerService {
  constructor (private router: Router) {
    //
  }

  getNavigationExtras (activeTab: string): NavigationExtras {
    return {
      queryParams: {
        active: activeTab,
      },
    };
  }

  redirectTo (type: SideBarTabs) {
    switch (type) {
    case SideBarTabs.ANALYTICS:
      this.router.navigate([ NavigationScreenRoute.ANALYTICS ]);
      break;
    case SideBarTabs.INVENTORY:
      this.router.navigate([ NavigationScreenRoute.INVENTORY ]);
      break;
    case SideBarTabs.CUSTOMER_CHAT:
      this.router.navigate([ NavigationScreenRoute.CUSTOMER_CHAT ]);
      break;
    case SideBarTabs.USERS:
      this.router.navigate([ NavigationScreenRoute.USERS ]);
      break;
    case SideBarTabs.CATEGORY_INVENTORY_TABS:
      this.router.navigate([ NavigationScreenRoute.CATEGORY_INVENTORY_TABS ]);
      break;
    case SideBarTabs.MANAGE_CATEGORIES:
      this.router.navigate([ NavigationScreenRoute.MANAGE_CATEGORIES ]);
      break;
    case SideBarTabs.SUGGESTION_PROMPTS:
      this.router.navigate([ NavigationScreenRoute.SUGGESTION_PROMPTS ]);
      break;
    case SideBarTabs.ASK_ME_ANYTHING:
      this.router.navigate([ NavigationScreenRoute.ASK_ME_ANYTHING ]);
      break;
    case SideBarTabs.BROADCAST:
      this.router.navigate([ NavigationScreenRoute.BROADCAST ]);
      break;
    case SideBarTabs.CMS:
      this.router.navigate([ NavigationScreenRoute.CMS ]);
      break;
    case SideBarTabs.MODERATE_REQUESTS:
      this.router.navigate([ NavigationScreenRoute.MODERATE_REQUESTS ]);
      break;
    case SideBarTabs.USER_FEEDBACK:
      this.router.navigate([ NavigationScreenRoute.USER_FEEDBACK ]);
      break;
    case SideBarTabs.FEEDBACK:
      this.router.navigate([ NavigationScreenRoute.FEEDBACK ]);
      break;
    case SideBarTabs.ACCOUNT:
      this.router.navigate([ NavigationScreenRoute.ACCOUNT ]);
      break;
    case SideBarTabs.LOGOUT:
      this.router.navigate([ NavigationScreenRoute.LOGOUT ]);
      break;
    default:
      break;
    }
  }
}
